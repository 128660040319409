<template>
  <div class="title">Ability List</div>
  <div v-if="abilities" class="mt-2">
    <div v-for="ability in abilities" :key="ability" class="columns">
      <div class="column is-1">
        <button class="button is-fullwidth" @click="editOnDocId(ability.docid)">Edit</button>
        <button class="button is-fullwidth" @click="deleteByDocId(ability.docid)">Delete</button>
      </div>
      <div class="column">
        <ability-view v-bind="ability" @deleted="getAbilities()"></ability-view>
      </div>
    </div>
  </div>
</template>

<script>
import AbilityView from '@/components/AbilityView'
import { mapGetters, mapActions } from 'vuex'
import router from '@/router'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'abilities'
    ])
  },
  methods: {
    ...mapActions([
      'fetchAbilities',
      'deleteAbilityById',
    ]),
    editOnDocId(docid) {
      router.push({name:'AbilityEditSideBySide',params: {docid:docid}});
    },
    deleteByDocId(docid) {
      this.deleteAbilityById(docid);
    },
  },
  created() {
    if(!this.abilities) {
      this.fetchAbilities()
    }
  },
  components: {
    AbilityView,
  }
}
</script>

<style lang="scss" scoped>

</style>